import React from 'react';
import { graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Layout from '../components/layout/Layout';
import '../css/content.css';

const Legal = ({ data }) => {
  const { content, title } = data.content;
  return (
    <Layout>
      <section className="pt-20 md:pt-20">
        <div className="container  mx-auto px-8">
          <h1 className="text-xl">
            <strong>{title}</strong>
          </h1>
          <br />
          <br />
          <div className="content">{content && renderRichText(content)}</div>
        </div>
      </section>
    </Layout>
  );
};

export default Legal;

export const pageQuery = graphql`
  query($slug: String!) {
    content: contentfulLegalPage(slug: { eq: $slug }) {
      title
      content {
        raw
      }
    }
  }
`;
